import React from 'react'
import {Box , Text , Center , UnorderedList , ListItem , Link} from '@chakra-ui/react'


function Terms() {
  return (
    <>
    <Box bg="#040B11" fontFamily="heading" width="100%">
        <Center>
            <Box width={["85%","75%","75%","75%"]} pt={10} pb={10}>
                <Text as="h1" fontSize={["2.4rem","2.8rem","3rem","3rem"]} color="brand.900" fontWeight="bold" >
                    Site Terms
                </Text>

                <Text pt={10} as="h3" fontSize={["1rem","1.1rem","1.2rem","1.2rem"]} color="brand.900" fontWeight="bold" opacity="0.8">
                Please read and understand these Terms of Use before using the Cratch website. By accessing this site (including any official press releases and informational materials integrated hereto; collectively, the "Web Site"), you agree to be bound by the terms hereunder, and the terms of our Terms of Use and Privacy Policy.
                </Text><br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                This Web Site is published by Cratch Limited (“Cratch") for general information regarding the proposed Cratch System. Aside from providing this Web Site and certain other public materials, the Limited may conduct one or more blockchain token sale events, with any tokens ("CRTC Tokens", "the Tokens" or "CRTC") sold in such events declared and agreed to be non-securities (or "utility tokens") to the full extent permitted by law, whether of the United States or any other applicable jurisdiction. Without prejudice to such general non-security treatment in respect of the Tokens, this Web Site makes reference to prospective treatment of Tokens sold as "securities" pertaining to particular instances within specific jurisdictions.
                </Text>
                <br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                This Web Site does not constitute an offer or a solicitation to purchase securities, as that term is defined in the United States under the Securities Act of 1933 (the "Securities Act" or "the Act"), or in any other jurisdiction. A definitive and legally-binding offer to purchase or sell securities can only be made through a formal offering agreement. Any decision to purchase tokens in connection with such prospective offering should be made solely on the basis of the information contained in any then-current offering agreement, which should be carefully-reviewed and evaluated in consultation with the prospective purchaser's own legal, accounting, investment, tax and any other applicable advisors, in view of the prospective purchaser's own circumstances. In any case, this Web Site does not constitute a purchase recommendation regarding any Tokens proposed or intended to be offered or sold by Cratch.
                </Text>
                <br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                The Web Site is subject to continual revision by the core team and/or legal advisors of Cratch, is not intended to be complete, and may be updated from time to time with no obligation by the Cratch core team to inform you of any changes. This Web Site shall not be legally binding or enforceable by any recipient against Cratch or any of its agents or affiliates.
                </Text>
                <br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                All statements, estimates and financial information contained in the Web Site, made in any press releases or in any place accessible by the public and oral statements that may be made by Token Generator and which are not statements of historical fact constitute "forward-looking statements". Such forward-looking statements involve known and unknown risks, uncertainties and other factors which may cause actual events or results, performance or achievements to differ materially from the estimates or the results implied or expressed in such forward-looking statements.
                </Text>
                <br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                With the exception of Accredited Investors who have been verified pursuant to Rule 506(c) of Regulation D of the United States Securities Act, Cratch will not accept token purchase offers from any U.S. person (within the meaning of Regulation S under the Securities Act). No registration statement has been filed with the United States Securities and Exchange Commission ("SEC") or any U.S. state securities authority with respect to sales of the Tokens. None of the Tokens sold or to be sold by Cratch have been or will be registered under the Act.
                </Text>
                <br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                Any Tokens purchased from Cratch are subject to resale restrictions in respect of the U.S. and certain other jurisdictions, as well as general terms and conditions applicable to numerous jurisdictions.
                </Text>
                <br/>
                <Text as="h3" fontSize="1.3rem" color="brand.900" fontWeight="bold">
                Changes to the Terms of Use
                </Text><br/>
                <UnorderedList fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100">
                    <ListItem>We reserve the right to amend or withdraw this Website, any service or material provided via the Website, at our sole discretion and without prior notice. No guarantee is given that the Website or any of its content will always be made publicly available or without interruption. We will not be held liable for any reason if all or any part of the site is unavailable for any time or any period. At times we may wish to restrict access to portions of the Website or the entire Website, once again this is at our sole discretion and can be made without any prior notice.</ListItem>
                </UnorderedList><br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                As a user you are responsible for:
                </Text> <br />
                <UnorderedList fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" spacing={3}>
                    <ListItem>Making any and all required arrangements for access to the Website.</ListItem>
                    <ListItem>Making sure that anyone who accesses the Website through your internet connection is aware of these Terms of Use and that they comply with them.</ListItem>
                    <ListItem>Ensuring that the registration information you provide on the Website to access certain resources is correct, current, and complete. You in turn agree that the information you provide for use of the Website and any services or interactive features is governed by our Privacy Policy, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</ListItem>
                </UnorderedList><br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                Please be aware that increased caution should be used when inputting personal information to the Website when you are using a public or shared computer so others are not able to see or record your personal information. It is your responsibility to make sure that you take suitable steps to protect yourself.
                </Text> <br />
                <Text as="h3" fontSize="1.3rem" color="brand.900" fontWeight="bold">
                Intellectual Property Rights
                </Text><br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                The Website and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by the Company, its licensors or other providers of such material and are protected by copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.
                </Text>
                <br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                By using the site you agree to use the Website for non-commercial, personal use only. You also agree not to reproduce, distribute modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Website, except as follows:
                </Text>
                <br/>
                <UnorderedList fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" spacing={3}>
                    <ListItem>Your computer may temporarily store copies of such materials in RAM to facilitate your accessing and viewing those materials.</ListItem>
                    <ListItem>Making sure that anyone who accesses the Website through your internet connection is aware of these Terms of Use and that they comply with them.</ListItem>
                    <ListItem>You may store files that are automatically cached by your Web browser for display purposes.</ListItem>
                    <ListItem>You may print or download one copy of a reasonable number of pages of the Website for your own personal, non-commercial use and not for further reproduction, publication or distribution.</ListItem>
                    <ListItem>If Cratch provides desktop, mobile or other applications for download at any point, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by any additional end user license agreement for such applications.</ListItem>
                
                </UnorderedList><br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                As a user you must not:
                </Text> <br />
                <UnorderedList fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" spacing={3}>
                    <ListItem>Modify copies of any materials from the Website.</ListItem>
                    <ListItem>Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from the Website.</ListItem>
                    <ListItem>Access or use for any commercial purposes any part of the Website or any services or materials available through the Website.</ListItem>
                    
                </UnorderedList><br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                If you wish to make any use of materials on the Website other than the terms set out in this section, please address your request to: <Link href="mailto:contact@cratch.io" color="brand.900" textDecoration="underline">contact@cratch.io</Link>.
                </Text> <br />
                <Text as="h3" fontSize="1.3rem" color="brand.900" fontWeight="bold">
                Trademarks
                </Text><br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                The Company name, the Company logo and all related names, logos, product and service names, designs and slogans are trademarks of the Company or its affiliates or licensors. You must not use such trademarks without getting prior written permission from the Company. All other names, logos, product and service names, designs and slogans on this Website are the trademarks of their respective owners.
                </Text> <br />
                <Text as="h3" fontSize="1.3rem" color="brand.900" fontWeight="bold">
                Changes to the Cratch Website
                </Text><br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                The Company reserves the right to update the content on this website from time to time and without warning, but its content is not necessarily complete or up-to-date and should not be considered so. Any of the material on the Website may be out of date at any given time, and we are under no obligation to update such material.
                </Text> <br />
                <Text as="h3" fontSize="1.3rem" color="brand.900" fontWeight="bold">
                Other Terms and Conditions
                </Text><br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                Additional terms and conditions may also apply to specific portions, services or features of the Website, including registration. All such additional terms and conditions are hereby incorporated by reference into these Terms of Use. In the event of terms that are directly conflicting between these Terms of Use and terms of conditions for registration, the additional terms and conditions shall control and be treated as the primary terms and conditions.
                </Text> <br />
                <Text as="h3" fontSize="1.3rem" color="brand.900" fontWeight="bold">
                Linking to the Cratch Website and Social Media Features
                </Text><br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                It is acceptable to link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part without our express written consent from the Company.
                </Text> <br />
                <Text as="h3" fontSize="1.3rem" color="brand.900" fontWeight="bold">
                Links from the Website
                </Text><br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                When the Website links to other third-party sites and resources, these links are provided for your convenience only. These may include links contained in advertisements, including banner advertisements and sponsored links. The Company however has no control over the contents of those sites or resources, and we accept no responsibility for them or for any loss or damage that may arise from your use of them.
                </Text> <br />
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                If you decide to access any of the third party websites linked to the Website, you do so entirely at your own risk and subject to the terms and conditions of use for such websites including any privacy policy. When using a third-party website it is your responsibility to read their individual terms of use and privacy policy statements. The Company reserves the right to withdraw links without notice.
                </Text> <br />
                <Text as="h3" fontSize="1.3rem" color="brand.900" fontWeight="bold">
                Your Comments and Concerns
                </Text><br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                We welcome your feedback, and any comments, support requests, and other communications should be sent to <Link href="mailto:contact@cratch.io" color="brand.900" textDecoration="underline">contact@cratch.io</Link>.
                </Text> <br />
                
            </Box>
        
        </Center>
        
    </Box>
    </>
  )
}

export default Terms