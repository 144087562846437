import React from 'react'
import {Box  , Text , Button , Center , Image , Link} from '@chakra-ui/react';
import hero from '../../../assets/hero.webp'
import {motion} from 'framer-motion'
import Typical from 'react-typical'

function Hero() {

const Animate = {
  hidden : {
    x: -100,
    opacity:0
  },
  visible : custom => ({
    x: 0,
    opacity:1,
    transition : {delay : custom * 0.2},
  }),
}


  return (
    <> 
    <Box height={["auto","auto","auto","auto"]} bg="#0E0C1A" d="flex" alignItems="center"  justifyContent="center" fontFamily="heading" >
          
        <Box width="100%" height="100%" pr={[0,0,20,20,40]} pl={[10,20,20,20,40]} pb={[10,20,20,20]} pt={[0,10,10,10]}>
            <Center width="100%" height="100%" > 
              <Box width={["100%","100%","50%","50%"]} pb={10}>
                  <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={Animate}
                          custom={1}
                          >
                      
                      <Text as="h1" pt={[5,0,0,0]} color="brand.900" fontSize={["2rem","2rem","2.5rem","3.1rem"]} fontWeight="bold" lineHeight={["3rem","3rem" , "3rem", "4rem"]}>
                      First Ever Decentralized<Typical
                        steps={['MetaStreaming Platform', 1000 ]}
                        wrapper="h1"

                    />
                        </Text>
                        </motion.div>
                        <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={Animate}
                          custom={1.5}
                          >
                        <Text as="h3" color="brand.100" fontSize={["1.2rem ","1.2rem","1.3rem","1.3rem"]} pt={4} width={["100%","95%","100%","98%"]} pb={10}>
                        Watch, create and join the first decentralized live & video streaming platform in the Metaverse.
                        
                        </Text>
                        
                                <Link href='https://account.cratch.io/' isExternal><Button _active={{
                                    color: 'brand.500',
                                    bg: 'linear-gradient(to right top, #ced16b, #d1d25f, #d5d352, #d9d343, #ddd432, #dfd828, #e2db1a, #e4df01, #e4e700, #e4ef00, #e3f700, #e2ff00);',
                                    textDecoration: "none",
                                  }} width ="12rem" color="brand.500" height="3rem" fontSize={["1.1rem","1.2rem","1.3rem","1.3rem"]} bg="yellow.100" fontWeight="bold" _hover={{
                                    color: 'brand.500',
                                    bg: 'linear-gradient(to right top, #ced16b, #d1d25f, #d5d352, #d9d343, #ddd432, #dfd828, #e2db1a, #e4df01, #e4e700, #e4ef00, #e3f700, #e2ff00);',
                                    textDecoration: "none",
                                  }}
                                  
                                  >
                                    Join Now
                                </Button>
                            </Link>
                          </motion.div>
                        </Box>
              <Box width={["50%","50%"]} display={['none' , 'none' , 'block' , 'block']} pl={10}>
                <Image src={hero} width="100%" alt="image" />


              </Box>

            </Center>

        </Box>

    </Box>



    </>
  )
}

export default Hero