import React from 'react'
import {Box ,Text, Image , Center, Button} from '@chakra-ui/react'
import {motion} from 'framer-motion';
import {Link as ReachLink } from "@reach/router"
import { Link } from '@chakra-ui/react'
import members from '../../../../assets/members.gif'
function Sixth() {
  const Animate = {
    hidden : {
      x: -100,
      opacity:0
    },
    visible : custom => ({
      x: 0,
      opacity:1,
      transition : {delay : custom * 0.2, type: 'spring'},
    }),
  }
  return (
    <>

    <Box bgColor="#0A0914" fontFamily="heading">

    <Center bg="#0A0914" width="100%" height="100%">
            <Box width={["85%","80%","79%","79%"]} d={["grid","grid","flex","flex"]}  mt={20}  height="100%" pb={10} justifyContent="center" alignContent="center" alignItems="center">
            <Box width={["100%","100%","50%","50%"]} height="100%"  d={['block' , "block" , "none" , "none"]} pb={5}>
            <Image src={members} alt="team" />

                </Box>
                <Box width={["100%","100%","50%","50%"]} >
                <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={Animate}
                          custom={2}
                          >
                    <Text fontSize={["1.9rem","2.1rem","2.1rem","2.5rem"]} fontWeight="bold" mt={5} lineHeight="2.6rem" color="brand.900">
                    The Cratch Team
                    </Text>
                    </motion.div>
                    <Text as="p" color="brand.100" fontSize={["1rem","1.1rem","1.2rem","1.2rem"]} mt={5} width={["100%","100%","95%","95%"]}>
                    Our team takes pride in introducing the Cratch ecosystem. The Cratch team has been working hard, with the firm belief that this will facilitate and improve content creation live,video streaming.
                    </Text>
                    <motion.div whileTap={{scale : 0.9}}>
                    <Link as={ReachLink} to="/team">
                    <Button _hover={{
                      bg: 'rgb(245, 248, 250 , 0.9)'
                    }} _active={{
                      bg: 'rgb(245, 248, 250 , 0.9)'
                    }} bg="brand.900" mt={10} width="12.5rem" height="50px" fontWeight="bold" borderRadius="10px" fontSize={["1.1rem","1.1rem","1.2rem","1.2rem"]} color="#100515">
                        Meet the Team
                    </Button>
                    </Link>
                </motion.div>
                </Box>
                <Box width={["100%","100%","50%","50%"]}  height="100%" pl={[0,10,20,20]} d={['none' , "none" , "block" , "block"]} >
                <Image  src={members} alt="team"/>

                </Box>
            </Box>
        </Center>



      
       </Box>
    </>
  )
}

export default Sixth