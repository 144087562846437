import React from 'react'
import {Box ,Text , Image , Button, Link} from '@chakra-ui/react';
import { IoLogoGameControllerB } from 'react-icons/io';
import { GiDiamondTrophy} from 'react-icons/gi';
import {SiStreamlit , SiHiveBlockchain} from 'react-icons/si';
import splinter from '../../assets/splinter.webp'
import axie from '../../assets/axie.webp'
import alien from '../../assets/alien.webp'
import {motion} from 'framer-motion'

const Animate = {
    hidden : {
      scale: 0.5,
      opacity:0
    },
    visible : custom => ({
      scale: 1,
      opacity:1,
      transition : {delay : custom * 0.2},
    }),
  }

const content = [
    {
        icon: <IoLogoGameControllerB size="5rem" fill="#7B5BFB"/>,
        text: 'Play 2 Earn & Esports enthusiasts can play, watch, and enjoy their favourite esports tournaments on Cratch.',
    },
    {
        icon: <SiStreamlit size="5rem" fill="#F1ED4D"/>,
        text: 'On Cratch, content creators and live streamers can upload their videos, set up their own virtual studios in MetaCratch, build their brand, and connect with fans.'
    },
    {
        icon: <GiDiamondTrophy size="5rem" fill="#00C373"/>,
        text: 'Crypto enthusiasts and video consumers can join Cratch’s Watch and Earn program to earn rewards as $CRTC tokens for use on Cratch, MetaCratch and MobiCratch.'
    },
    {
        icon: <SiHiveBlockchain size="5rem" fill="#FB5B78"/>,
        text: 'Crypto lovers can buy lands and video content digital collectible NFTs that will be securely stored and authenticated on the blockchain.'
    }
]

const tournaments = [
    {
        img : axie,
        heading: 'Axie Infinity'
    },
    {
        img : splinter,
        heading: 'Splinterlands'
    },
    {
        img : alien,
        heading: 'Alien Worlds'
    },
]


export default function Section() {
  return (
    <>
                {content.map(({icon , text}) => (

                            <Box width={["100%","100%","100%","25%"]} pt={10} mr={8} >
                                
                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        variants={Animate}
                        custom={2}
                        
                        >
                            
                                {icon}</motion.div>
                                <Text as="p" color="brand.900" fontSize={["0.9rem","1rem","1.1rem","1.1rem"]} pt="6%" width="95%">
                                {text}
                                </Text>
                            </Box>

                ))}
                        

    </>
  )
}

export  function Tournaments() {
    return(
        <>
        {tournaments.map(({img , heading}) => (

                <Box width="33.33%" pt="7%" pr={[2,2,14,14]}>
                    <Image borderRadius="10px" src={img} width="100%" alt="logo" height="80%"/>
                    <Text as="p" color="brand.900" fontSize={["0.9rem","1rem","1.1rem","1.3rem"]} pt={6} fontWeight="bold">
                    {heading}
                    </Text>
                    <Link isExternal href="https://account.cratch.io">
                       <Button _hover={{
                        bg: 'transparent'
                            }} border="solid 1px white" bg="transparent" mt={7} width={["6rem","6.2rem","7.rem","7.5rem"]} height="40px" fontWeight="bold" borderRadius="10px" fontSize={["0.8rem","0.9rem","1rem","1.0rem"]} color="brand.900">
                            Learn more
                        </Button> 
                    </Link>
                    
                </Box>
        ))}


       
        </>
    )
}


