import React from 'react'
import {Box , Center , Text , List, ListItem,ListIcon} from '@chakra-ui/react'
import {motion} from 'framer-motion';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {CgCircleci} from 'react-icons/cg';
import {MdCheckCircle} from 'react-icons/md';
import {BsRecordCircleFill} from 'react-icons/bs';
import map from "../../assets/roadmap.webp"
function Roadmap() {
  return (
    <>
    <Box bg="#0E0C1A" fontFamily="heading">
    <Box height={["50vh","60vh","70vh","90vh"]} bgImage={`url('${map}')`} bgSize="cover" bgRepeat="no-repeat" bgPosition="center" d="flex" alignItems="center" justifyContent="center" fontFamily="heading">
          
          <Box width="100%" height="100%" pr={[10,10,10,40]} pl={[10,10,10,40]}>
              <Center width="100%" height="100%" > 
                <Box width={["100%","100%","60%","50%"]} pb={10} >
                    <motion.div
                            initial={{ opacity: 0, x: -100 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 100 }}
                            transition={{duration: 0.4, ease: 'easeInOut'}}
                            >
                        <Text as="h1" color="brand.900" fontSize={["2rem","2rem","2.9rem","3.5rem"]} fontWeight="bold">
                            Roadmap
                          </Text>
                          <Text as="h3" color={["#e8e8e8","#e8e8e8","#e8e8e8","brand.100"]} fontSize={["1.3rem","1.4rem"]} pt={4} width={ ["80%", "80%"]} pb={10}>
                          Check out important milestones on Cratch’s journey from ideation through execution.
                          </Text>
                         
                            </motion.div>
                          </Box>
                <Box width={["40%","50%"]} display={['none' , 'none' , 'block' , 'block']}>
                  {/* <Image src={roadmap} width="100%" alt="image" /> */}
                </Box>
  
              </Center>
  
          </Box>
        </Box>

    <Box width="100%" bg="#0A0914" pl={[5,20,20,20]} pr={[5,20,20,20]}>
            <Box bg="#0A0914" pt={10} pb={10}>
              <Center width={["auto","auto","100%","100%"]}><Text as="h1" fontSize="2.4rem" fontWeight="bold" color="brand.900" pt={5} pr={[60  , 60 , 60 ,0] }>2024</Text></Center>
              
            <VerticalTimeline>
                  
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: '#100515', color: '#fff' }}
                    contentStyle={{ background: '#3780D0', color: '#fff' , fontSize: '1.8rem'}}
                    icon={<BsRecordCircleFill />}
                    
                  >
                     <h4 className="vertical-timeline-element-subtitle" style={{fontWeight: 'bold' , fontSize: '1.6rem'}}>Step 1</h4>
                    <List spacing={2} fontSize={['0.8rem', '0.8rem','1.1rem']}>
                        <ListItem>

                          {/* <ListIcon as={MdCheckCircle} color='#10CC52' />
                          Cratch Mainnet Launch
                        </ListItem> */}
                        <ListIcon as={MdCheckCircle} color='#10CC52'  />
                          Cratch Mainnet Launch
                        </ListItem>
                        <ListItem>
                          <ListIcon as={MdCheckCircle} color='#10CC52'    />
                          NFT Subscriptions
                        </ListItem>
                        <ListItem>
                          <ListIcon as={MdCheckCircle} color='#10CC52'   />
                          RTMP Streaming Server Development
                        </ListItem>

                        {/* You can also use custom icons from react-icons */}

                      </List>
                  </VerticalTimelineElement>

                  <VerticalTimelineElement
                    className="vertical-timeline-element--work "
                    contentStyle={{ color : '#191A22' ,fontSize: '1.8rem'}}
                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}       
                    iconStyle={{ background: '#3780D0', color: '#fff' }}
                    icon={<BsRecordCircleFill />}
                  >

                    <h4 className="vertical-timeline-element-subtitle" style={{fontWeight: 'bold' , fontSize: '1.6rem'}}>Step 2</h4>
                    
                    <List spacing={2} fontSize={['0.8rem', '0.8rem','1.1rem']}>
                        <ListItem>
                          <ListIcon as={MdCheckCircle} color='#10CC52'  />
                          MetaCratch & MobiCratch Initial Development
                        </ListItem>
                      <ListItem>
                          <ListIcon as={MdCheckCircle} color='#10CC52'  />
                          Virtual Studios & Virtual Lands Creation
                        </ListItem>
                        <ListItem>
                          <ListIcon as={MdCheckCircle} color='#10CC52'/>
                          Reward System Development
                        </ListItem>
                        
                        
                        

                        {/* You can also use custom icons from react-icons */}

                      </List>
                  </VerticalTimelineElement>
                  

                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: '#100515', color: '#fff' }}
                    contentStyle={{ background: '#3780D0', color: '#fff' , fontSize: '1.8rem'}}
                    icon={<BsRecordCircleFill />}
                    
                  >
                  <h4 className="vertical-timeline-element-subtitle" style={{fontWeight: 'bold' , fontSize: '1.6rem'}}>Step 3</h4>
                    <List spacing={2} fontSize={['0.8rem', '0.8rem','1.1rem']}>

                        <ListItem>
                          <ListIcon as={MdCheckCircle} color='#10CC52' />
                          MobiCratch Launch App Store & PlayStore
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CgCircleci}  />
                          MetaCratch Main Launch
                        </ListItem>

                        <ListItem>
                          <ListIcon as={CgCircleci} />
                          Virtual Lands & Virtual Studios Public Sale
                        </ListItem>

                        

                       
                        
                        {/* You can also use custom icons from react-icons */}

                      </List>
                  </VerticalTimelineElement>

                  <VerticalTimelineElement
                    className="vertical-timeline-element--work "
                    contentStyle={{ color : '#191A22' ,fontSize: '1.8rem'}}
                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}       
                    iconStyle={{ background: '#3780D0', color: '#fff' }}
                    icon={<BsRecordCircleFill />}
                  >
                    
                    <h4 className="vertical-timeline-element-subtitle" style={{fontWeight: 'bold' , fontSize: '1.6rem'}}>Step 4</h4>
                      <List spacing={2} fontSize={['0.8rem', '0.8rem','1.1rem']}>

                       <ListItem>
                          <ListIcon as={CgCircleci} />
                         CRTC Token Public Sale
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CgCircleci} />
                          NFTs & Virtual Studios Distribution 
                        </ListItem>

                        
                        <ListItem>
                          <ListIcon as={CgCircleci}  />
                          P2E Tournaments Hosting
                        </ListItem>
                    </List>
                  </VerticalTimelineElement>


                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: '#100515', color: '#fff' }}
                    contentStyle={{ background: '#3780D0', color: '#fff' , fontSize: '1.8rem'}}
                    icon={<BsRecordCircleFill />}
                    
                  >
                    <h3 className="vertical-timeline-element-subtitle" style={{fontWeight: 'bold' , fontSize: '1.6rem'}}>Step 5</h3>
                    <List spacing={2} fontSize={['0.8rem', '0.8rem','1.1rem']}>
                        <ListItem>
                          <ListIcon as={CgCircleci}  />
                          Partnerships with Content Creators & Streamers 
                        </ListItem>

                        <ListItem>
                          <ListIcon as={CgCircleci} />
                         Marketing Campaign & Popular Exchanges Listing
                        </ListItem>

                        <ListItem>
                          <ListIcon as={CgCircleci} />
                          More Partnerships & Development
                        </ListItem>

                       
                        
                        {/* You can also use custom icons from react-icons */}

                      </List>
                  </VerticalTimelineElement>
                  <VerticalTimelineElement
                      iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                      icon={<BsRecordCircleFill />}
                    />
                  </VerticalTimeline>

            
            </Box>
    </Box>
        

      </Box>
    </>
  )
}

export default Roadmap