import React from 'react'
import {Box} from '@chakra-ui/react'
import Section from './Section'
import logo from '../../../../assets/logo.webp'
import mobicratch from '../../../../assets/mobicratch.webp'
import metacratch from '../../../../assets/metacratch.webp'
import Typical from 'react-typical'
import bg from '../../../../assets/bg.svg'


function Second() {


  return (
    <>    
                          
    <Box d={["grid","grid","flex","flex"]} gap={[5,5,0,0]} width="100%" bg="#100515" height={["auto","auto","105vh","105vh"]}  alignItems="center" justifyContent="center"  pl={[0,10,10,0,0]} pr={[0,0,10,0,0]} pt={10} pb={20}>
    

    <Section borderColor="#0088FF" backgroundColor="#0E122C" logo={logo} width="7.5rem" heading="Shift towards Content Creation, Live & Video Streaming" paragraph="Besides bringing content creation, live streaming & video Sharing to the Metaverse, Cratch is solving many problems
of popular platforms by 
not charging any hidden fees for transactions,
not collecting user data and 
not running any disturbing ads."
       button="More About Cratch" open={false} link="/platform"/>
      <Section borderColor="#ab1bee" backgroundColor="#1F072B" width="11rem" logo={metacratch} heading="New Experience of Live & Video Streams in the Metavesre" paragraph="With MetaCratch you will get a new experience of video sharing and content creation.
Join the community and connect with your favorite streamers and influencers in the metaverse."
       button="More About MetaCratch" open={false} link="/metaverse" />

       <Section borderColor="#B93D77" backgroundColor="rgb(81, 17, 51,0.5)" width="11rem" logo={mobicratch} heading="Stay Connected, Have Fun and Gain Special Rewards" paragraph="MobiCratch will help you stay connected to the platform and gain rewards in every minute you spend on it.
With MobiCratch you will never miss any new videos, live streams or messages."
       button="More About MobiCratch" number={1} open={true}/>

    </Box>
    <Box bgImage={`url(${bg})`} fontFamily="heading" letterSpacing={20} color="#692E6B" pt={10} pb={10} textAlign="center" fontSize={["4rem","4rem","6rem","6rem"]} fontWeight="bold">
            <Typical
        steps={['CREATE', 1500, 'WATCH', 1500,'PLAY', 1500, 'EARN', 1500]}
        loop={Infinity}
        wrapper="h1"
      />
            </Box>
            
    </>
  )
}

export default Second