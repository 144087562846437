import React from 'react'
import {Box , Center , Text , Image , Button,Link} from '@chakra-ui/react';
import logo from '../../assets/logo.webp'
import bg from '../../assets/bg.webp'
import sign from '../../assets/sign.webp'
import win from '../../assets/win.webp'
import claim from '../../assets/claim.webp'
import Section from './section'
import Third from '../Home/sections/Third/Third';
import {Tournaments} from './section'
import {motion} from 'framer-motion'
function Platform() {
    const Animate = {
        hidden : {
          y: -100,
          opacity:0
        },
        visible : custom => ({
          y: 0,
          opacity:1,
          transition : {delay : custom * 0.2, type: 'spring'},
        }),
      }
      const AnimateSection = {
        hidden : {
          x: -100,
          opacity:0
        },
        visible : custom => ({
          x: 0,
          opacity:1,
          transition : {delay : custom * 0.2, type: 'spring'},
        }),
      }


    const [img , setImg] = React.useState(sign)
    const [id, setId] = React.useState(1)

  return (
      <>
      <Box fontFamily="Archivo,sans-serif">
            
            <Box height={window.screen.height * window.devicePixelRatio >= 1600 ? "75vh": "auto"} width="100%" bg={`url("${bg}") no-repeat top center`} bgSize="auto 100%">

           
                <Center>
                <Box width={["100%","100%","70%","70%" , "60%"]} height="100%" pt={[10,10,20,10]}>
                    <Center>
                        <Image src={logo} width="10rem" alt="logo" mt={[0,5,5,0]}/>
                     </Center>
                      <motion.div
                        initial={{ opacity: 0, y: -100 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 100 }}
                        transition={{duration: 0.3, ease: 'easeInOut'}}
                        >
                     <Center>
                        <Text as="h1" pl={10} pt={5} fontSize={["1.5rem","2.5rem","2.5rem","3.2rem"]} d={["hidden" , "block" , "block" , "block"]} fontWeight="bold" lineHeight="2.6rem" color="brand.900">
                        New Video Sharing Experience 
                        </Text>
                        
                    </Center>
                        <Center width="100%" >
                        <Text as="p" color="brand.100" d={["none" , "block" , "block" , "block"]} fontSize="1.2rem" pt={3} width={["100%","90%","90%","75%"]}>
                        Cratch is creating unlimited possibilities for users at the intersection of content creation, video sharing and crypto by introducing all enthusiasts to in and out of video NFTs, rewards for watching and connecting with your favourite streamers.
                        </Text>
                        </Center>  
                        </motion.div>
                        <Center width="100%">
                        <Text  width="100%" fontSize={["1.5rem","2.5rem","3rem","3.2rem"]} fontWeight="bold" pl={[10,10,20,20]} pt={[10,40,80,80]} lineHeight="2.6rem" color="brand.900">
                           
                        </Text>
                    </Center>  
                        <Center>
                            <Text as="p" color="brand.100" fontSize={["0.8rem","1.1rem","1.2rem","1.2rem"]} pl={[5,10,10,5]} pt={3} width={["85%","95%","88%","88%"]}>
                            
                            </Text>
                        </Center>                
                </Box>
                </Center> 
            </Box>
           
            <Center bg="#090114" pt={[5,5,10,10]}>
            <Link href='https://account.cratch.io/' isExternal>
                    <Button _hover={{
                      bg: '#AB1BEE',
                      transform: 'scale(1.1)'
                    }} _active={{
                      bg: '#AB1BEE',
                      transform: 'scale(1.1)'
                    }} bg="#AB1BEE" width="13.75rem" height="50px" fontWeight="bold" borderRadius="10px" fontSize={["1rem","1.1rem","1.2rem","1.2rem"]} color="brand.900">
                        Discover 
                    </Button>
                    </Link>
            </Center>



            <Box height="auto" bg="#090114" pb={10}>
                <Center >
                    <Box width="80%" borderTop="1px dashed rgba(255,255,255,.2)" mt={10}></Box>
                </Center>
                <Center>
                            <Box  width="70%" height="100%">
                                <Center>
                                <motion.div
                                        initial="hidden"
                                        whileInView="visible"
                                        variants={Animate}
                                        custom={2}
                                        
                                        >
                                    <Text fontSize={["2rem","2.2rem","2.3rem","2.5rem"]} fontWeight="bold" mt={16} lineHeight="2.6rem" color="brand.900">
                                    Watch, Create & Earn
                                    </Text>
                                    </motion.div>
                                </Center>   
                                <Center>
                                    <Text as="p" color="brand.100" fontSize={["1rem","1.1rem","1.2rem","1.2rem"]} mt={3} pl={[0,8,0,0]}>
                                        Earn rewards by creating content or watching your favourite streamer.

                                    </Text>
                                </Center>

                            <Box display={["none","none" , "none", "block" , "block"]}>
                                    <Box d="flex"mt={10} height="100%" >
                                    
                                        <Box width="45%" height="100%"  borderRadius="10px"  >
                                            <motion.div
                                        initial="hidden"
                                        whileInView="visible"
                                        custom={1.3}
                                        variants={AnimateSection}
                                        
                                        >
                                            <Box height="150px" width="95%" borderRadius="10px" mb={5} border={id === 1 ? "solid 1px #ab1bee" : "solid 1px rgba(255,255,255,.2)"} cursor="pointer" onClick={() => {setId(1); setImg(sign)}}>
                                                <Text color="brand.900" as="h2" fontSize="1.2rem" pt={8} pl={10} fontWeight="bold">Sign up</Text>
                                                <Text as="p" color="brand.100" fontSize="1.0rem" pl={10} width="70%">
                                                Click on the <span style={{fontWeight: 'bold'}}>Connect</span> button to create or login to your account
                                                </Text>
                                            </Box>
                                            <Box height="150px" width="95%" borderRadius="10px" mb={5} border={id === 2 ? "solid 1px #ab1bee" : "solid 1px rgba(255,255,255,.2)"} cursor="pointer" onClick={() => {setId(2); setImg(win)}}>
                                                <Text color="brand.900" as="h2" fontSize="1.2rem" pt={8} pl={10} fontWeight="bold">Watch and earn</Text>
                                                <Text as="p" color="brand.100" fontSize="1.0rem" pl={10} width="70%">
                                                Earn $CRTC Tokens for watching live streams and videos
                                                </Text>
                                            </Box>
                                            <Box height="150px" width="95%" borderRadius="10px" border={id === 3 ? "solid 1px #ab1bee" : "solid 1px rgba(255,255,255,.2)"} cursor="pointer" onClick={() => {setId(3); setImg(claim)}}>
                                                <Text color="brand.900" as="h2" fontSize="1.2rem" pt={8} pl={10} fontWeight="bold">Claim</Text>
                                                <Text as="p" color="brand.100" fontSize="1.0rem" pl={10} width="80%">
                                                Shop on Cratch & MetaCratch store using earned points
                                                </Text>
                                            </Box>
                                       </motion.div> </Box>

                                        <Box width="50%" height="100%" ml={10}>
                                        <Image mt={5} src={img} width="100%" alt="logo" height="100%"/>
                                        </Box>
                                        
                                    </Box>
                            </Box>

                            <Box display={["block","block" , "block", "none" , "none"]}>
                                    <Box mt={10} height="100%" >
                                        <Box width="100%" height="100%"  borderRadius="10px"  >
                                        <motion.div
                                        initial="hidden"
                                        whileInView="visible"
                                        custom={2}
                                        variants={AnimateSection}
                                        
                                        >
                                            <Box height="150px" width="100%" borderRadius="10px" pb={5} border="solid 1px rgba(255,255,255,.2)" >
                                                <Text color="brand.900" as="h2" fontSize="1.2rem" pt={8} pl={10} fontWeight="bold">Sign up</Text>
                                                <Text as="p" color="brand.100" fontSize="1.0rem" pl={10} width="80%">
                                                Click on the <span style={{fontWeight: 'bold'}}>Connect</span> button to create or login to your account
                                                </Text>
                                            </Box>
                                            </motion.div>

                                            <Box width="100%" height="100%" >
                                                <Image pt={5} src={sign} alt="logo" />
                                            </Box>
                                            <motion.div
                                        initial="hidden"
                                        whileInView="visible"
                                        custom={2}
                                        variants={AnimateSection}
                                        
                                        >
                                            <Box height="150px" width="100%" borderRadius="10px" mt={10} pb={5} border="solid 1px rgba(255,255,255,.2)" >
                                                <Text color="brand.900" as="h2" fontSize="1.2rem" pt={8} pl={10} fontWeight="bold">Watch and earn</Text>
                                                <Text as="p" color="brand.100" fontSize="1.0rem" pl={10} width="80%">
                                                Earn $CRTC Tokens for watching live streams and videos
                                                </Text>
                                            </Box>
                                            </motion.div>
                                            <Box width="100%" height="100%" >
                                                <Image pt={5} src={win} alt="logo" />
                                            </Box>
                                            <motion.div
                                        initial="hidden"
                                        whileInView="visible"
                                        custom={2}
                                        variants={AnimateSection}
                                        
                                        >
                                            <Box height="150px" width="100%" borderRadius="10px" mt={10} border="solid 1px rgba(255,255,255,.2)"  >
                                                <Text color="brand.900" as="h2" fontSize="1.2rem" pt={5} pl={10} fontWeight="bold">Claim</Text>
                                                <Text as="p" color="brand.100" fontSize="1.0rem" pl={10} width="80%">
                                                Shop on Cratch & MetaCratch store using earned points
                                                </Text>
                                            </Box>
                                            </motion.div>
                                            <Box width="100%" height="100%" >
                                                <Image pt={5} src={claim}  alt="logo" />
                                            </Box>
                                        </Box>

                                    </Box>
                            </Box>



                                </Box>
                                
                       
                </Center>

               
            </Box>
            <Center bg="#090114" pb={20}>
                <Box height="auto" bg="#090114" width={["80%","80%","70%","70%"]}>
                    <Center >
                        <Box width="100%" borderTop="1px dashed rgba(255,255,255,.2)" mt="5%"></Box>
                    </Center>

                    <Box width="100%">
                        <Text as="p" color="brand.100" fontSize={["1rem","1.1rem","1.2rem","1.2rem"]} pt={20} textTransform="uppercase">
                            HOW IT WORKS
                        </Text>
                        <Text width="100%" fontSize={["1.4rem","1.6rem","1.8rem","2.0rem"]} fontWeight="bold" mt={[5,5,8,8]} lineHeight={["2rem","2rem","2.2rem","2.2rem"]} color="brand.900">
                        Cratch is dominating live streaming & video sharing spaces with world-class content and technology superiority.
                        </Text>
                    </Box>
                    <Box d={["grid","grid","grid","flex"]} width="100%" gridTemplateColumns="1fr 1fr">
                            <Section />

                    </Box>

                </Box>
            </Center>


            <Center bg="#090114" width="100%">
                <Box height="auto" bg="#090114" width={["80%","80%","70%","70%"]} pb={20}>
                    <Center >
                        <Box width="100%" borderTop="1px dashed rgba(255,255,255,.2)"></Box>
                    </Center>

                    <Box >

                        <Text fontSize={["2rem","2.2rem","2.3rem","2.5rem"]} fontWeight="bold"  lineHeight="2.2rem" color="brand.900" pt={20} >
                        Weekly Tournaments for P2E Lovers
                        </Text>

                        <Text as="p" color="brand.100" fontSize="1.2rem" pt={[5,5,5, 10,10]} width={["100%","100%","90%","70%" , "60%"]} >
                            On Cratch, Play to Earn and Esport players can compete in tournaments, set up their own tournaments, build their brand and connect with fans.

                        </Text>
                        <motion.div whileTap={{scale : 0.9}} 
                                        initial="hidden"
                                        whileInView="visible"
                                        custom={2}
                                        variants={AnimateSection}
                                        
                                        >
                        <Link href='https://meta.cratch.io/' isExternal>
                            <Button _hover={{
                                bg: '#AB1BEE'
                                }} _active={{
                                bg: '#AB1BEE'
                                }} bg="#AB1BEE" width="210px" height="50px" fontWeight="bold" borderRadius="10px" fontSize="1.2rem" color="brand.900" mt={8} mb={[10,10, 0 , 0]}>
                                    Visit Studio
                            </Button></Link>
                        </motion.div>
                    </Box>
                    <Box d="flex" width="100%">
                        <Tournaments />

                    </Box>

                </Box>
            </Center>
                <Box pb={5} bg="radial-gradient(circle at 70% -10%, #131024, #040C14)">
                    <Third 
                      heading="Want to know what others are saying about Cratch?" 
                      bg="radial-gradient(circle at 70% -10%, #131024, #040C14)" 
                      cardColor="rgba(255,255,255,.01)"
                      paragraph="Join one of the fastest-growing community of Metaverse lovers, crypto enthusiasts, live streamers and content creators. We are intentional about building an international community that is currently supported in 5 languages with more to come. Connect with your tribe within our community."
                      
                      />
                    </Box>
                   
        </Box>

    </>
  )
}

export default Platform