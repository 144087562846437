import React from 'react'
import {Box ,Text} from '@chakra-ui/react'
import video from '../../../../assets/trailer.mp4'
import {motion} from 'framer-motion'
import './style.css'

function First() {
  const Animate = {
    hidden : {
      y: 100,
      opacity:0
    },
    visible : custom => ({
      y: 0,
      opacity:1,
      
      transition : {delay : custom * 0.2 , type: 'spring'},
    }),
  }
  return (
    <>
    <Box height="auto" pt={20} pb={[20,20,20,20]} bg="#100515" alignItems="center" fontFamily="heading" justifyContent="center" d={["grid","grid","flex","flex"]} pl={[10,20,20,20,40]} pr={[10,20,20,20,40]}>
   <Box width={["100%","100%","60%", "80%","80%"]}>
     <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={Animate}
                          custom={1}
                          >
      <Text as="h2" fontSize={["1.2rem","1.5rem","1.5rem","1.8rem","2rem"]}  lineHeight="2rem" pr={[0,0,5,10,20]} color="brand.900" fontWeight="semibold" pb={[10,10,0,0]}>
      We’re a blockchain company looking to create an entirely new experience in Live Streaming, Video Sharing, and Content Creation.
      </Text>
      </motion.div>
   </Box>
    
      <video controls autoPlay muted loop  className='videos'>

        <source  src={video} type="video/mp4" >
        </source>
      </video>
    </Box>


    </>
  )
}

export default First