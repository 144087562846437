import React from 'react'
import {Box,Text , Button , Center,Link} from '@chakra-ui/react'
import {FiArrowUpRight} from "react-icons/fi"


function Section({children , text , btnText , bg, link}) {
  return (
    <Box mr={[0,7,7,7]} pl={7} mb={[5 , 5 , 0 , 0]} padding={8}  width={["100%","100%","22%","22%"]}  border="1px solid rgba(255, 255, 255, 0.1)" borderRadius="15px" bg={`${bg}`} justifyContent="center" alignItems="center" textAlign="center">
        
            <Center><i >{children}</i></Center>
            <Text pt={3} pb={3} color="brand.900" fontSize={["1.5rem","1.5rem","1.7rem","2.3rem"]} fontWeight="bolder">{text}</Text>
            <Center>
            <Link href={link} isExternal>
            <Button mt={2} height="35px" width="6.875rem" bg="transparent" color="brand.900" border="1px solid white" rightIcon={<FiArrowUpRight  size="1.2rem" />} _hover={{
              bg:'transparent'
            }}>
                {btnText}
            </Button></Link>
            
            </Center>
        
    </Box>
  )
}

export default Section