import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useState
} from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Box , Image , Text, Button , AspectRatio, Center , Modal,
  ModalOverlay,
  ModalContent,

  ModalBody,
  ModalCloseButton,useDisclosure} from '@chakra-ui/react'
import { FaAngleRight } from "react-icons/fa";
import { AiOutlineCloseSquare } from "react-icons/ai";
import {Link as ReachLink } from "@reach/router"
import { Link } from '@chakra-ui/react'
import video from '../../../../assets/app.mp4'
import play from '../../../../assets/play-store.webp';
import store from '../../../../assets/app-store.webp';
import bg from '../../../../assets/bg1.svg';


import './styles.css'

const Modals = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);


  useImperativeHandle(ref, () => {
    return {
      open: () => setOpen(true),
      close: () => setOpen(false)
    };
  });
  
  return (
    <AnimatePresence>
      {open && (
        <>
          <motion.div
            initial={{
              opacity: 0
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.2
              }
            }}
            exit={{
              opacity: 0,
              transition: {
                delay: 0.2
              }
            }}
            onClick={() => setOpen(false)}
            className="modal-backdrop"
          />
          <motion.div
            initial={{
              scale: 0
            }}
            animate={{
              scale: 1,
              transition: {
                duration: 0.2
              }
            }}
            exit={{
              scale: 0,
              transition: {
                delay: 0.2
              }
            }}
            className="modal-content-wrapper"
          >
            <motion.div
              className="modal-content"
              initial={{
                x: 100,
                opacity: 0
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.2,
                  duration: 0.2
                }
              }}
              exit={{
                x: 100,
                opacity: 0,
                transition: {
                  duration: 0.2
                }
              }}
            >
              {props.children}
            </motion.div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
  });


function Section({borderColor , backgroundColor , logo , heading , paragraph , button , number , open , link , width}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [ids , setids] = useState(0)
  const modalRef = useRef();
  
  const values = [
      {
          
          id: 1,
          videoUrl : "https://www.youtube.com/embed/QhBnZ6NPOY0",
          textDescription : "Enjoy your time with MobiCratch, Stay connected with your beloved community and friends. Receive valuable NFTs and $CRTC Tokens for every minute you spend using MobiCratch."
      }
  ]
  return (
    <>
    <Box height={window.screen.height * window.devicePixelRatio >= 1600 ? "90%" : "100%"} width={["80%","80%","30%","24%"]} bg={`${backgroundColor}`} mr={[10,10,2,7]} ml={[8,10,0,7]} pb={[8,10,0,0]} borderTop={`4px solid ${borderColor}`} fontFamily="heading" >
    <Box height="82%" width="100%">
        <Image  width={width} src={logo} alt="logo" mt={10} ml={10}/>
        <Text as="h3" color="brand.900" fontFamily="heading" fontSize={["1.2rem","1.3rem","1.2rem","1.3rem","1.6rem"]} lineHeight={["1.8rem","1.8rem","1.8rem", "1.7rem", "2.2rem"]} width="80%" mt={[5,5,3,3,8]} ml={[10,10,5,8,10]} fontWeight="bold">
           {heading}
        </Text>
        <Text as="p" color="brand.100" fontSize={window.screen.height * window.devicePixelRatio >= 1600 ? "1.2rem" : "1rem"} width="80%" ml={[10,10,5,8,10]} mt={5} > 
            {paragraph}
        </Text>
        </Box>
        <Box height="60px" >
        
                {open == true && <Center d="flex" borderTop="1px solid rgb(173, 171, 171 , 0.1)" height="100%"  width="100%">
                      <Button mt={1} width="100%" height="100%" bg='transparent' color="brand.900" _hover={{
                          bg: 'transparent',
                          color:"brand.900"
                      }} _active={{
                        textDecoration: 'none',
                        background: 'transparent'
                      }} textAlign="center" onClick={() => {if(open == true) {modalRef.current.open();} setids(number)}}>
                          {button} <i textAlign="center" style={{marginLeft : '1rem'}}><FaAngleRight size="1.2rem" fill='rgba(255, 255, 255, 0.6)'/></i>
                      </Button>
                    
                      </Center>}     

                      {open == false && 
                      <Link as={ReachLink} to={`${link}`} _hover={{ textDecoration: 'none',
                        background: 'transparent'}} _active={{
                        textDecoration: 'none',
                        background: 'transparent'
                      }}>
                      <Center d="flex" borderTop="1px solid rgb(173, 171, 171 , 0.1)" height="100%"  width="100%">
                      <Button mt={1} width="100%" height="100%" bg='transparent' color="brand.900" _hover={{
                          textDecoration: 'none',
                          bg: 'transparent',
                          color:"brand.900"
                      }} textAlign="center"  _active={{
                        textDecoration: 'none',
                        background: 'transparent'
                      }} onClick={() => {if(open == true) {modalRef.current.open();} setids(number)}}>
                          {button} <i textAlign="center" style={{marginLeft : '1rem'}}><FaAngleRight size="1.2rem" fill='rgba(255, 255, 255, 0.6)'/></i>
                      </Button>
                    
                      </Center>
                      </Link>}            
                   
                      

        </Box>
        <Box>
        <Modals ref={modalRef}>
              {ids !== 0 && (<>
              
                      {values.map(({id, videoUrl, textDescription}) => (
                            <>
                              
                                  {id === ids && (
                                      <>
                                      <Box top="0" right="0" pos="absolute"><motion.div whileTap={{scale : 0.9}}><AiOutlineCloseSquare  size="2.3rem" cursor="pointer" onClick={() => {modalRef.current.close()}} fill='rgba(255, 255, 255, 0.6)'/></motion.div></Box>
                                   
                                  <AspectRatio ratio={4/3} mt={5} ml={2} mr={2} >
                                    <iframe
                                      loop={true}
                                      maxH='40vh'
                                      title='info'
                                      src={video}
                                      allowFullScreen
                                      autoPlay

                                    />
                                  </AspectRatio>
                                  <Center width="100%" height="100%">
                                    <Text fontSize={window.screen.height * window.devicePixelRatio >= 1600 ? "1.3rem" : "1rem" } color="brand.100" pt={5} pl={5} pr={5}>{textDescription}</Text>
                                    
                                    </Center>
                                    <Center width="100%" pt={5}>
                                      <Box d="flex" width="80%" pl={5}>
                                          <Box width="50%">
                                            <Link href="https://play.google.com/store/apps/details?id=io.cratch.myapp" isExternal>
                                            <Image src={play} alt="play-store" width="80%" cursor="pointer" />
                                            </Link>
                                            
                                            </Box>
                                        
                                          <Box width="50%"><Image src={store} alt="app-store" width="80%" cursor="pointer"  onClick={onOpen} /></Box>
                                      </Box> 
                                    </Center>   
                                      </>
                                  )}
                              
                          </>
                      ))}
              </>)}
    </Modals>
    
    
  </Box>

  <Modal  isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody  borderRadius="5px" bgImage={`url('${bg}')`} fontFamily="Archivo,sans-serif">
            <Center width="100%" pt={10}>
              <Text as="h1" fontSize="1.5rem" fontWeight="bold" color="brand.900">Coming Soon...</Text>
              
             
            </Center> 
              <Center width="100%" pt={5} pb={10}>
                <Text as="h1" fontSize="1.2rem" fontWeight="bold" color="yellow.900" textTransform="uppercase">STAY TUNED</Text>
              </Center> 
         </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
    
    

    </>
  )
}

export default Section


